import { Validation } from '../../../common/validation';
import { LeaveNameEnum } from '../../../types';
import { WorkTimeUnitType } from '../../../types/organization.types';
import getNormalText from '../../../util/getNormalText';

export const checkWarningMsg = (
  value: string,
  leaveName: string,
  previousValue: number,
  workTimeUnitType?: string | null,
) => {
  let pattern;

  if (workTimeUnitType === WorkTimeUnitType.DAYS_HALF_DAYS) {
    pattern = Validation.HalfDayPattern;
  } else {
    pattern = Validation.DecimalNumberPattern;
  }

  if (!value) {
    throw new Error(`Please enter ${getNormalText(leaveName)}`);
  } else if (
    (Number(value) < previousValue && leaveName !== LeaveNameEnum.SICK_LEAVE) ||
    !String(value).match(pattern)
  ) {
    throw new Error('Incorrect input format/ Mismatch with previous input');
  }

  return true;
};
